// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-flaschenpost-js": () => import("./../../../src/pages/flaschenpost.js" /* webpackChunkName: "component---src-pages-flaschenpost-js" */),
  "component---src-pages-handwerk-js": () => import("./../../../src/pages/handwerk.js" /* webpackChunkName: "component---src-pages-handwerk-js" */),
  "component---src-pages-impressum-und-datenschutz-js": () => import("./../../../src/pages/impressum-und-datenschutz.js" /* webpackChunkName: "component---src-pages-impressum-und-datenschutz-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kunden-js": () => import("./../../../src/pages/kunden.js" /* webpackChunkName: "component---src-pages-kunden-js" */),
  "component---src-pages-mannschaft-js": () => import("./../../../src/pages/mannschaft.js" /* webpackChunkName: "component---src-pages-mannschaft-js" */),
  "component---src-pages-preise-danke-js": () => import("./../../../src/pages/preise/danke.js" /* webpackChunkName: "component---src-pages-preise-danke-js" */),
  "component---src-pages-preise-js": () => import("./../../../src/pages/preise.js" /* webpackChunkName: "component---src-pages-preise-js" */),
  "component---src-templates-handwerk-subpage-js": () => import("./../../../src/templates/handwerkSubpage.js" /* webpackChunkName: "component---src-templates-handwerk-subpage-js" */),
  "component---src-templates-job-js": () => import("./../../../src/templates/job.js" /* webpackChunkName: "component---src-templates-job-js" */),
  "component---src-templates-jobs-js": () => import("./../../../src/templates/jobs.js" /* webpackChunkName: "component---src-templates-jobs-js" */)
}

